
function Js(){
    
    return(<div>
        <div>
    JavaScript is the backbone of modern web development, enabling dynamic, interactive, and user-friendly applications across platforms. With extensive experience in JavaScript within business environments, I build responsive, high-performance applications that drive user engagement and meet business objectives.
</div>
<div>
    I have a strong command of JavaScript and have worked with key frameworks, libraries, and tools, including:
</div>
<div>
    <ul>
        <li><strong>Frontend:</strong> React, Vue, Angular</li>
        <li><strong>Backend:</strong> Node.js, Express, NestJS</li>
        <li><strong>Testing & Automation:</strong> Jest, Cypress</li>
    </ul>
</div>
<div>
    By leveraging JavaScript’s flexibility across these frameworks and tools, I develop highly scalable, maintainable Applications that perform seamlessly across devices and browsers. My focus is on creating applications that are both user-centric and technically sound, optimized for modern development needs.
</div>

    </div>)
}

export default Js