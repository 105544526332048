import Navbar from "../../../navbar/navbar";
import "../bpStyle.css"
function todoPost(){
return (
<div>
    <Navbar i={2}/>
    <div className="bpStyle">
    <div className="text-3xl pb-2">Work in Progress</div>
    <div>        
    Nothing here yet. But the functionality exists already for writing, so. I am currently writing some code for a museum to automate tours through it. It is 80% done, and I will soon make some test runs with it through the museum. I hope to have everything functional before that happens.
        </div>
    <div>Also, I have two old Python projects I want to do some summaries on: one talking about CNN's about transfer learning, and the other about LSTM for stock market prediction.</div>
    <div>The latter will be used in stock market prediction, which I want to have done by around July 2025, when I have more time.</div>
    <div> I also look forward to making some projects in Java with Spring or something in Go. I haven't had any use cases as of late, and I feel like I'm getting a bit out of touch with those languages.</div>
    <div> Oh, and I will obviously write about the experience of building this website and its deployment. TL;DR is my web building skills have significantly increased over the last few months, and I can build better web apps much quicker now.</div>
    </div>
</div>)
}

export default todoPost;