
function Java(){
    
    return(
    <div>
        Java is a cornerstone of enterprise software development, powering scalable, secure, and high-performance applications across industries. With extensive experience in Java, I specialize in creating robust backend systems and well-structured applications that deliver reliability and efficiency.
        I am skilled in working with a range of popular Java frameworks, tools, and libraries, including:
        <div>
            <ul>
                <li><strong>Backend Development:</strong> Spring Boot, Spring MVC, Hibernate</li>
               <li><strong>Testing & Automation:</strong> JUnit, Mockito, Cucumber</li>
            </ul>
        </div>
        Through leveraging these frameworks, I build Java applications that are both modular and maintainable, adhering to industry best practices. Whether developing RESTful APIs, working with relational databases, I emphasize performance and scalability at every step. By choosing Java, I deliver software that meets the rigorous demands of today’s enterprise environments.
    </div>)
}

export default Java