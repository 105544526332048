
function Ts(){
    
    return(
    <div> 
        <div>
            TypeScript is a cornerstone of modern web development, known for its powerful features that enhance both reliability and scalability. With several years of experience writing TypeScript code in professional settings, I leverage this language to build clean, efficient, and maintainable applications.
        </div>
        <div>
            I have extensive experience working with key frameworks and tools within the TypeScript ecosystem, including:
        </div>
        <div>
            <ul>
                <li><strong>Frontend:</strong> Angular, Vue, React</li>
                <li><strong>Backend:</strong> Node.js, Express</li>
                <li><strong>Testing & Automation:</strong> Jest, Cypress</li>
            </ul>
            Note that this is not an exhausticve list, many business usecases have required different frameworks.
        </div>
        <div>
            By combining TypeScript with these frameworks and tools, I create robust applications that meet complex business needs while ensuring code quality and consistency across the development lifecycle.
        </div>
    </div>

    )
}

export default Ts